<template>
  <el-dialog
    :visible="visible"
    title="订单核销"
    width="500px"
    v-on="$listeners"
  >
    <el-form>
      <el-form-item>
        <span>{{ name }}</span>
        <span>{{ phone }}</span>
      </el-form-item>
      <el-row v-for="(item, index) in goods" :key="index" :gutter="10">
        <el-col :span="6">
          <el-form-item :label="item.name" />
        </el-col>
        <el-col :span="6">
          <el-form-item :label="item.price" />
        </el-col>
        <el-col :span="6">
          <el-form-item :label="item.subheading" />
        </el-col>
        <el-col :span="6">
          <el-form-item :label="`数量：${item.count}`" />
        </el-col>
      </el-row>
      <el-input
        :value="value"
        placeholder="请输入或使用扫码枪扫码"
        class="input-with-select"
        @input="$emit('update:value', $event)"
        @change="$emit('enter')"
      >
        <template slot="prepend">核销码</template>
      </el-input>
    </el-form>
    <div class="tool m-t-20 m-b-20 text-center">
      <el-button @click="$emit('update:visible', false)">取消</el-button>
      <el-button type="primary" @click="$emit('write')">核销</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    data: Object,
    value: String
  },
  computed: {
    name() {
      return this.data?.name ? this.data?.name : '';
    },
    phone() {
      return this.data?.phone ? this.data?.phone : '';
    },
    goods() {
      return this.data?.orderGoodsDetails ? this.data?.orderGoodsDetails : [];
    }
  }
};
</script>
